<template>
  <v-container>
    <!-- notification area -->
    <div class="sticky-row">
      <v-alert
        v-show="error"
        class="custom-alert"
        transition="fade-transition"
        type="error"
        dense
      >
        <v-row align="center">
          <v-col class="grow">
            {{ errorMessage }}
          </v-col>
          <v-col class="shrink">
            <v-btn title="close" icon>
              <v-icon @click="error = false"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-alert
        v-show="success"
        class="custom-alert"
        transition="fade-transition"
        type="success"
        dense
      >
        <v-row align="center">
          <v-col class="grow">
            {{ successMessage }}
          </v-col>
          <v-col class="shrink">
            <v-btn title="close" icon>
              <v-icon @click="success = false"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate color="primary" />
          Please wait...
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <div class="image-container">
              <v-img
                src="@/assets/my_account.png"
                :aspect-ratio="aspectRatio"
                class="darkened-image"
              />

              <div class="centered-text">
                <h1 :class="headerText">My Account</h1>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <h3 class="d-flex align-center mb-5">
                  <v-icon class="mr-2">mdi-account-outline</v-icon>
                  Account Information
                </h3>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <th style="width: 250px"></th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>{{ user.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone:</td>
                        <td>{{ user.phone }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-btn
                  @click="handle_open_update_password_form"
                  class="mt-5"
                  :small="smallBtn"
                >
                  update password
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3 class="mb-5">
                  <v-icon class="mr-2">mdi-lan-connect</v-icon> Associated
                  Companies
                </h3>

                <v-expansion-panels v-model="panels" multiple>
                  <v-expansion-panel class="bordered">
                    <v-expansion-panel-header>
                      Main Company
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div v-for="company in user.companies" :key="company.id">
                        <v-row>
                          <v-col>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td width="150">Company Name</td>
                                    <td style="text-transform: capitalize">
                                      {{ company.name }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Lender</td>
                                    <td>{{ company.lender | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Appraiser</td>
                                    <td>{{ company.appraiser | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lawyer</td>
                                    <td>{{ company.lawyer | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Accounts</td>
                                    <td class="py-2 u">
                                      <v-data-table
                                        v-if="windowWidth >= 600"
                                        :headers="account_headers"
                                        :items="company.accounts"
                                        :items-per-page="-1"
                                        item-key="id"
                                        class="bordered"
                                        hide-default-footer
                                        dense
                                      />
                                    </td>
                                  </tr>

                                  <tr v-if="windowWidth < 600">
                                    <td colspan="2">
                                      <div
                                        v-if="company.accounts.length === 0"
                                        class="text-center grey--text my-4"
                                      >
                                        No accounts associated with this company
                                      </div>

                                      <div
                                        v-for="item in company.accounts"
                                        :key="item.id"
                                        class="rounded-lg my-5"
                                      >
                                        <v-simple-table class="bordered" dense>
                                          <template v-slot:default>
                                            <tbody>
                                              <tr>
                                                <td width="150">
                                                  Account Name
                                                </td>
                                                <td>{{ item.name }}</td>
                                              </tr>
                                              <tr>
                                                <td>Category</td>
                                                <td>{{ item.category }}</td>
                                              </tr>
                                              <tr>
                                                <td>Type</td>
                                                <td>{{ item.type }}</td>
                                              </tr>
                                              <tr>
                                                <td>Account Number</td>
                                                <td>
                                                  {{ item.account_number }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Renewal Responsibility</td>
                                                <td>
                                                  {{
                                                    item.renewal_responsibility
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </template>
                                        </v-simple-table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="bordered">
                    <v-expansion-panel-header>
                      Authorized Companies
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <div
                        v-for="company in user.auth_companies"
                        :key="company.id"
                      >
                        <v-row>
                          <v-col>
                            <v-simple-table dense>
                              <template v-slot:default>
                                <tbody>
                                  <tr>
                                    <td width="150">Company Name</td>
                                    <td style="text-transform: capitalize">
                                      {{ company.name }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Lender</td>
                                    <td>{{ company.lender | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Appraiser</td>
                                    <td>{{ company.appraiser | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lawyer</td>
                                    <td>{{ company.lawyer | yes_no }}</td>
                                  </tr>
                                  <tr>
                                    <td>Accounts</td>
                                    <td class="py-2 u">
                                      <v-data-table
                                        v-if="windowWidth >= 600"
                                        :headers="account_headers"
                                        :items="company.accounts"
                                        :items-per-page="-1"
                                        item-key="id"
                                        class="bordered"
                                        hide-default-footer
                                        dense
                                      />
                                    </td>
                                  </tr>

                                  <tr v-if="windowWidth < 600">
                                    <td colspan="2">
                                      <div
                                        v-if="company.accounts.length === 0"
                                        class="text-center grey--text my-4"
                                      >
                                        No accounts associated with this company
                                      </div>

                                      <div
                                        v-for="item in company.accounts"
                                        :key="item.id"
                                        class="rounded-lg my-5"
                                      >
                                        <v-simple-table class="bordered" dense>
                                          <template v-slot:default>
                                            <tbody>
                                              <tr>
                                                <td width="150">
                                                  Account Name
                                                </td>
                                                <td>{{ item.name }}</td>
                                              </tr>
                                              <tr>
                                                <td>Category</td>
                                                <td>{{ item.category }}</td>
                                              </tr>
                                              <tr>
                                                <td>Type</td>
                                                <td>{{ item.type }}</td>
                                              </tr>
                                              <tr>
                                                <td>Account Number</td>
                                                <td>
                                                  {{ item.account_number }}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Renewal Responsibility</td>
                                                <td>
                                                  {{
                                                    item.renewal_responsibility
                                                  }}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </template>
                                        </v-simple-table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="updating_password" width="500" persistent>
      <Update_Password_form
        :user="this.user"
        @cancel="handle_close_update_password_form"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";
import Update_Password_form from "@/components/my_accounts/Update_Password.vue";

export default {
  components: {
    Update_Password_form,
  },

  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      success: false,
      successMessage: "",

      panels: [0, 1],

      windowWidth: window.innerWidth,

      user: {},

      updating_password: false,

      account_headers: [
        { text: "Name", value: "name" },
        { text: "Category", value: "category" },
        { text: "Type", value: "type" },
        { text: "Account Number", value: "account_number" },
        { text: "Renewal Responsibility", value: "renewal_responsibility" },
      ],
    };
  },

  computed: {
    aspectRatio() {
      if (this.windowWidth >= 1264) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 2.5;
      } else if (this.windowWidth >= 600) {
        return 2;
      } else {
        return 1.5;
      }
    },

    headerText() {
      if (this.windowWidth >= 600) {
        return "text-center text-h3 font-weight-black";
      } else {
        return "text-center text-h4 font-weight-black";
      }
    },

    smallBtn() {
      return this.windowWidth < 960;
    },
  },

  methods: {
    handle_open_update_password_form() {
      this.updating_password = true;
    },

    handle_close_update_password_form(success) {
      this.updating_password = false;
      if (success) {
        this.success = true;
        this.successMessage = "Password updated successfully";
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  filters: {
    ...filters,
  },

  async mounted() {
    let userInfo = await API().get("/api/user-info");
    this.user = userInfo.data;

    window.addEventListener("resize", this.handleResize);

    this.loading = false;
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
tr:hover {
  background-color: transparent !important;
}

.image-container {
  position: relative;
}

.darkened-image {
  filter: brightness(50%);
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  color: #ff6633;
  font-size: 1.5em;
  font-weight: bold;
}

.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.custom-alert .v-icon {
  align-self: center;
}

.sticky-row {
  position: sticky;
  top: 64px;
  z-index: 2;
}
</style>
