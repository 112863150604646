<template>
  <div>
    <v-card>
      <v-form @submit.prevent="updatePassword" ref="form">
        <v-card-title class="d-flex align-center justify-center">
          <h3>Update Password</h3>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rule.required]"
            :type="showNewPassword ? 'text' : 'password'"
            label="New Password"
            @click:append="showNewPassword = !showNewPassword"
            outlined
          />
          <v-text-field
            v-model="confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rule.required, passwordMismatch]"
            :type="showConfirmPassword ? 'text' : 'password'"
            label="Confirm Password"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" text color="primary">Update</v-btn>
          <v-btn class="ml-4" text @click="$emit('cancel', false)"> cancel </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import API from "@/plugins/API";
import rule from "@/plugins/rule";

export default {
  props: ["user"],

  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      showNewPassword: false,
      showConfirmPassword: false,
      rule: rule,
      
      passwordMismatch: (value) => {
        if (value !== this.newPassword) {
          return "Did not match to the new password";
        }
        return true;
      },
    };
  },

  methods: {
    async updatePassword() {
      const valid = this.$refs.form.validate();
      if (!valid) return;

      if (valid) {
        if (this.newPassword !== this.confirmPassword) {
          return;
        } else {
          try {
            console.log(this.user.id)

            const res = await API().patch("api/user-info/updateUserPassword", {
              user_id: this.user.id,
              newPassword: this.newPassword,
            });

            console.log(res);

            if (res.status === 201) {
              this.$emit("cancel", true);
            } else {
              alert("An error occurred");
            }
          } catch (error) {
            alert("An error occurred");
            console.log(error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
